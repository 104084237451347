import { configureStore } from '@reduxjs/toolkit'
import { configReducer } from '../reducers/configReducer'
import { daysReducer } from '../reducers/daysReducer'

export const store = configureStore({
  reducer: {
    days: daysReducer,
    config: configReducer
  }
})
