// eslint-disable-next-line space-before-function-paren
export const getHolidays = async (year) => {
  const url = 'https://apis.digital.gob.cl/fl/feriados'
  const resp = await fetch(url)

  const data = await resp.json()

  const holidays = data.map((data) => {
    return data.fecha
  })

  return holidays
}
