import React from 'react'

export const Navbar = () => {
  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="h-16 max-w-7xl text-center pt-2">
          <h1 className="text-white text-[25px]">Contador de Días Hábiles</h1>
        </div>
      </div>
    </nav>
  )
}
