import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Config } from '../components/Config'
import { Content } from '../components/Content'
import { Navbar } from '../components/partials/Navbar'

export const DaysScreen = () => {
  const config = useSelector((state) => state.config)

  useEffect(() => {
    localStorage.setItem('config-days', JSON.stringify(config))
  }, [config])

  return (
    <div className='bg-gray-600 h-screen'>
      <Navbar />
      <div className="container mx-auto px-5">
        <Config />
        <Content />
      </div>
    </div>
  )
}
