import dayjs from 'dayjs'

const initialConfig = {
  corrido: false,
  habil: false,
  administrativo: false
}

export const useCountDays = (startDate, days, config = initialConfig) => {
  const countDays = (startDate, days, config = initialConfig, holidays) => {
    const currentJs = dayjs(startDate)

    let finalDate = ''

    let i = 1
    if (config.corrido) {
      finalDate = currentJs.add(days, 'day')
      return finalDate.format('DD-MM-YYYY')
    }
    finalDate = currentJs.add(i, 'day')
    do {
      finalDate = currentJs.add(i, 'day')

      if (finalDate.format('d') === '0') {
        days++
        i++
        continue
      }
      if (holidays.includes(finalDate.format('YYYY-MM-DD'))) {
        days++
        i++
        continue
      }
      if (!config.habil && finalDate.format('d') === '6') {
        days++
        i++
        continue
      }
      i++
    } while (i <= days)

    return finalDate.format('DD-MM-YYYY')
  }

  return {
    countDays
  }
}
