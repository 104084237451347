import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { ChangeDays } from './ChangeDays'
import { useSelector } from 'react-redux'
import { useCountDays } from '../hooks/useCountDays'
import { getHolidays } from '../helpers/getHolidays'
import Calendar from 'react-calendar'

export const Content = () => {
  registerLocale('es', es)

  const [startDate, setStartDate] = useState(new Date())

  const [finalDate, setFinalDate] = useState('')

  const { countDays } = useCountDays()

  const { days, config } = useSelector((state) => state)

  useEffect(() => {
    async function fetchData() {
      const holidays = await getHolidays()
      setFinalDate(countDays(startDate, days.days, config, holidays))
    }
    if (days !== 0) fetchData()
  }, [days, startDate, config, countDays])

  return (
    <div className="mx-auto lg:max-w-10xl mt-3 ">
      <div className="bg-white p-8 rounded-xl grid md:grid-cols-5 ">
        <div className="col-start-2 col-span-3">
          <div className="text-[20px] text-center grid grid-cols-1 md:grid-cols-5">
            <div className="md:col-start-2 md:col-span-3">
              Seleccionar fecha:
              <Calendar
                onChange={(date) => setStartDate(date)}
                value={startDate}
              />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="es"
                className="btn-primary text-center"
                calendarClassName="text-center"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MM-Y"
                readOnly
              />
            </div>
          </div>
          <hr className="m-4" />
          <div className="text-center">
            <h1 className="text-[20px]">Cantidad de días a contar:</h1>
            <ChangeDays />
          </div>
          <hr className="m-4" />
          <div className="text-center">
            <h1 className="text-[20px]">Fecha Resultante:</h1>
            <h1 className="text-[30px] font-bold text-gray-700 ">
              {finalDate}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
